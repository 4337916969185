<template>
  <div id="login_box">
    <div class="box_left">
      <!--      <div class="service_box" v-if="serviceOpt">-->
      <!--        <div class="contact_wrap">-->
      <!--          <div class="qrcode_box">-->
      <!--            <img :src="serviceOpt.qrcode" alt="" />-->
      <!--            <div class="line"></div>-->
      <!--            <p>-->
      <!--              <i :class="serviceOpt.appIcon"></i>-->
      <!--              <span>{{ serviceOpt.app }}</span>-->
      <!--            </p>-->
      <!--          </div>-->
      <!--          <div class="contact_box">-->
      <!--            <p>Contact Us</p>-->
      <!--            <i class="el-icon-baseline-phone"></i>-->
      <!--            <a :href="`tel:${serviceOpt.contact}`">{{ serviceOpt.contact }}</a>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <tutorial-videos ref="tutorialVideosRef"></tutorial-videos>
      <!--      <el-carousel :interval="5000" v-else>-->
      <!--        <el-carousel-item class="slide1">-->
      <!--          <div class="bg_box">-->
      <!--            <img src="../../assets/login_banner/home_mask.png" alt="" />-->
      <!--          </div>-->
      <!--          <div class="content_box" style="&#45;&#45;h3-color: #2574eb; &#45;&#45;ul-color: #576077">-->
      <!--            <h3>-->
      <!--              {{ $t('login.loginBannerHomeTitle1') }}<br />-->
      <!--              {{ $t('login.loginBannerHomeTitle2') }}-->
      <!--            </h3>-->
      <!--            <div>-->
      <!--              <ul>-->
      <!--                <li v-for="num in 3" :key="num">-->
      <!--                  {{ $t('login.loginBannerHomeLi' + num) }}-->
      <!--                </li>-->
      <!--              </ul>-->
      <!--              <div class="img_box">-->
      <!--                <img :src="getBannerImg('home', num)" alt="" v-for="num in 3" :key="num" />-->
      <!--                <p>{{ $t('login.loginBannerHomeLabel') }}</p>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </el-carousel-item>-->
      <!--        <el-carousel-item class="slide2">-->
      <!--          <div class="bg_box">-->
      <!--            <img src="../../assets/login_banner/inventory_mask.png" alt="" />-->
      <!--          </div>-->
      <!--          <div class="content_box" style="&#45;&#45;h3-color: #ff8a00; &#45;&#45;ul-color: #776557">-->
      <!--            <h3>-->
      <!--              {{ $t('login.loginBannerInventoryTitle1') }}<br />-->
      <!--              {{ $t('login.loginBannerInventoryTitle2') }}-->
      <!--            </h3>-->
      <!--            <div>-->
      <!--              <ul>-->
      <!--                <li v-for="num in 3" :key="num">-->
      <!--                  {{ $t('login.loginBannerInventoryLi' + num) }}-->
      <!--                </li>-->
      <!--              </ul>-->
      <!--              <div class="img_box">-->
      <!--                <img :src="getBannerImg('inventory', num)" alt="" v-for="num in 3" :key="num" />-->
      <!--                <p>{{ $t('login.loginBannerInventoryLabel') }}</p>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </el-carousel-item>-->
      <!--        <el-carousel-item class="slide3">-->
      <!--          <div class="bg_box">-->
      <!--            <img src="../../assets/login_banner/visitor_mask.png" alt="" />-->
      <!--          </div>-->
      <!--          <div class="content_box" style="&#45;&#45;h3-color: #2fb480; &#45;&#45;ul-color: #577766">-->
      <!--            <h3>-->
      <!--              {{ $t('login.loginBannerVisitorTitle1') }}<br />-->
      <!--              {{ $t('login.loginBannerVisitorTitle2') }}-->
      <!--            </h3>-->
      <!--            <div>-->
      <!--              <ul>-->
      <!--                <li v-for="num in 3" :key="num">-->
      <!--                  {{ $t('login.loginBannerVisitorLi' + num) }}-->
      <!--                </li>-->
      <!--              </ul>-->
      <!--              <div class="img_box">-->
      <!--                <img :src="getBannerImg('visitor', num)" alt="" v-for="num in 4" :key="num" />-->
      <!--                <p>{{ $t('login.loginBannerVisitorLabel') }}</p>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </el-carousel-item>-->
      <!--      </el-carousel>-->
    </div>
    <div class="box_right">
      <div class="logo_box">
        <img src="../../assets/logo_mini.png" width="58" />
        <p>{{ $t('login.loginTitle') }}</p>
      </div>
      <!-- 联系方式和语言 -->
      <div class="contact_n_language_box">
        <!-- 联系方式 -->
        <el-dropdown class="contact_dropdown" trigger="click" placement="bottom-start" @visible-change="contactVisibleChange" :class="{ is_active: isContactPopperActive }">
          <span class="el-dropdown-link">
            <i style="font-size: 18px" class="el-icon-baseline-phone"></i>
            <span>{{ $t('login.contactUs') }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item class="contact_n_language_item" v-for="cItem in contactList" :key="cItem.id">
              <template v-if="cItem.id === 'email'">
                <i class="el-icon-email"></i>
                <span
                  ><a :href="'mailto:' + cItem.value">{{ cItem.label }}</a></span
                >
              </template>
              <template v-else>
                <i class="el-icon-baseline-phone"></i>
                <span
                  ><a :href="'tel:' + cItem.value">{{ cItem.label }}</a></span
                >
              </template>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 语言 -->
        <el-dropdown class="language_dropdown" trigger="click" placement="bottom-start" @command="switchLanguage">
          <span class="el-dropdown-link">
            <i style="font-size: 18px" class="el-icon-language"></i>
            <span>{{ currentLang.label }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item class="contact_n_language_item" v-for="lItem in languageList" :key="lItem.value" :command="lItem.value" :class="{ is_active: currentLang.value === lItem.value }">
              <span>{{ lItem.label }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <!-- 登录表单 -->
      <el-form :model="registerForm" :rules="registerFormRules" ref="registerFormRef" :validate-on-rule-change="false" @keyup.enter.native="login">
        <el-tabs v-model="loginMethod">
          <el-tab-pane :label="$t('register.welcomeReg')" name="phone">
            <el-form-item prop="phone">
              <el-input type="tel" @input="handleNumInput" @keyup.enter="checkPhone()" @blur="checkPhone()" :placeholder="$t('register.chinesePhoneTips')" v-model="registerForm.phone" class="areacode-box">
                <!-- <el-select v-model="registerForm.areacode" slot="prepend">
                                <el-option :key="countryKey" :label="country.name+(country.name? ' +' : '' )+country.id"
                                    :value="country.id" v-for="(country,countryKey) in countryList">
                                </el-option>
                            </el-select> -->
                <el-select v-if="!inputAreacodeShow" v-model="registerForm.areacode" slot="prepend" placeholder=""
                           @change="handelChangeAreacode" v-loading="areaCodeLoading" class="areacode-select">
                  <el-option :key="areacodeKey + areacodeItem.id" v-for="(areacodeItem, areacodeKey) in countryList" :label="areacodeItem.name" :value="areacodeItem.id"></el-option>
                  <el-option :key="areacodeItem.id" v-for="areacodeItem in inputAreacodeList" :label="areacodeItem.name" :value="areacodeItem.id"></el-option>
                  <el-option :label="$t('userinfoEditPhone.moreAreaCode')" :value="'more'"></el-option>
                </el-select>
                <el-input v-if="inputAreacodeShow" ref="countryInput" slot="prepend" v-model="countryVal" @change="handlecountryInput"></el-input>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input show-password maxlength="20" :placeholder="$t('register.configPass')" v-model="registerForm.password" auto-complete="new-password"> </el-input>
              <el-tooltip :content="$t('register.configNewPassFormatTip')" placement="bottom" effect="light">
                <i class="el-icon-question el-icon input-tip"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item prop="repwd">
              <el-input show-password maxlength="20" :placeholder="$t('register.rePass')" v-model="registerForm.repwd"> </el-input>
              <el-tooltip :content="$t('register.rePassTips')" placement="bottom" effect="light">
                <i class="el-icon-question el-icon input-tip"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item prop="code">
              <el-col :span="13">
                <el-input :placeholder="$t('register.phoneCode')" v-model="registerForm.code"> </el-input>
              </el-col>
              <el-col :span="10" :offset="1">
                <el-button :disabled="!(!isSend && wait == 60)" style="width: 100%" @click="getCode()" type="primary">
                  <template v-if="wait == 60">
                    {{ $t('register.sendCodeBtn') }}
                  </template>
                  <template v-else>
                    {{ $t('register.sendCodeBtning', { seconds: wait }) }}
                  </template>
                </el-button>
              </el-col>
              <el-tooltip :content="$t('register.phoneCodeTips')" placement="bottom" effect="light">
                <i class="el-icon-question el-icon input-tip"></i>
              </el-tooltip>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <div class="form_error_tips_box">
        <span v-show="errmsg">{{ errmsg }}</span>
      </div>

      <!-- 返回和登录提交按钮 -->
      <div class="btn_box">
        <el-button class="back_btn" type="primary" @click="backForm">
          {{ $t('common.back') }}
        </el-button>
        <el-button class="login_btn" type="primary" @click="register">{{ $t('register.immReg') }} </el-button>
      </div>
      <div class="copyright" v-if="langcode !== 'tw'">
        <span>
          {{ $t('common.copyrightDesc') }}
          <a v-if="langcode === 'zh'" style="text-decoration: none" href="https://beian.miit.gov.cn/">粤ICP备15010411号</a>
        </span>
      </div>
    </div>

    <el-dialog :title="$t('register.sendCode')" :visible.sync="codeDialog" destroy-on-close width="450px">
      <el-row :gutter="20">
        <el-col :span="18">
          <el-input :placeholder="$t('register.inputCodePlh')" v-model="imgcode"> </el-input>
        </el-col>
        <el-col :span="6">
          <img :src="imgsrc" style="cursor: pointer; width: 100%; display: block; border: 1px solid #ccc" @click="getImageCode" />
        </el-col>
      </el-row>
      <span style="color: red; display: block; margin: 0 auto" v-show="codeInputTip != ''">{{ codeInputTip }}</span>
      <div style="text-align: center; margin-top: 20px">
        <el-button type="primary" @click="sendCode">{{ $t('register.sendCode') }}</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="$t('common.alertPrompt')" :visible.sync="isShowRegisteredDialog" destroy-on-close width="600px">
      <div class="registered_tip_box">
        <div>
          <span class="tip_label">{{ $t('register.existAccountTip') }}</span>
          <div class="registered_btn_box">
            <el-button @click="goToLogin" type="primary">{{ $t('register.goToLoginLabel') }}</el-button>
          </div>
        </div>
        <div>
          <span class="tip_label">{{ $t('register.forgetPwdTip') }}</span>
          <div class="registered_btn_box">
            <el-button @click="goToRetrievePwd" type="primary">{{ $t('register.retrievePwdLabel') }}</el-button>
          </div>
        </div>
        <div>
          <span class="tip_label">{{ $t('register.goUseFlowTip') }}</span>
          <div class="registered_btn_box">
            <el-button @click="goToUseFlow" type="primary">{{ $t('register.useFlowLabel') }}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!--    <el-dialog :visible.sync="registerSuccessVisible" destroy-on-close custom-class="register-success-dialog"-->
    <!--               :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">-->
    <!--      <div>-->
    <!--        <div class="message_wrapper">-->
    <!--          <div class="el-icon-success"></div>-->
    <!--          <span class="tips_title">{{ $t('common.alertPrompt') }}</span>-->
    <!--          <span class="tips">{{ $t('register.regSuccessMsg') }}</span>-->
    <!--          <el-button type="primary" @click="closeRegisterSuccessDialog">{{ $t('common.sure') }}</el-button>-->
    <!--        </div>-->
    <!--        <el-divider>{{ $t('tutorialVideo.or') }}</el-divider>-->
    <!--        <div class="tutorial_wrapper">-->
    <!--          <div class="title">{{ $t('tutorialVideo.viewBasicCourse') }}</div>-->
    <!--          <div class="item_box">-->
    <!--            <a :href="vItem.link" target="_blank" v-for="vItem in tutorialVideos" :key="vItem.link">{{-->
    <!--                vItem.label-->
    <!--              }}</a>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </el-dialog>-->
  </div>
</template>
<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
